import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('Custom Parameters')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  color: "primary",
                  disabled: _ctx.currentJob.paused === 'N',
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('Save')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.customRequiredParameters.length || _ctx.customOptionalParameters.length)
          ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item_group, null, {
                  default: _withCtx(() => [
                    (_ctx.customRequiredParameters.length)
                      ? (_openBlock(), _createBlock(_component_ion_item_divider, {
                          key: 0,
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('Required Parameters')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              slot: "end",
                              fill: "clear",
                              color: "medium",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.getParameters('required'), 'Copied to clipboard')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: _ctx.copyOutline
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customRequiredParametersValue, (parameter, index) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: index,
                        lines: _ctx.currentJob.paused === 'Y' ? 'none': ''
                      }, {
                        default: _withCtx(() => [
                          (_ctx.currentJob.paused === 'Y')
                            ? (_openBlock(), _createBlock(_component_ion_input, {
                                key: 0,
                                label: parameter.name,
                                placeholder: parameter.name,
                                modelValue: parameter.value,
                                "onUpdate:modelValue": ($event: any) => ((parameter.value) = $event),
                                "helper-text": parameter.type
                              }, null, 8, ["label", "placeholder", "modelValue", "onUpdate:modelValue", "helper-text"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(parameter.name), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(parameter.value), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ], 64))
                        ]),
                        _: 2
                      }, 1032, ["lines"]))
                    }), 128)),
                    (_ctx.customOptionalParameters.length)
                      ? (_openBlock(), _createBlock(_component_ion_item_divider, {
                          key: 1,
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate('Optional Parameters')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_button, {
                              slot: "end",
                              fill: "clear",
                              color: "medium",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyToClipboard(_ctx.getParameters('optional'), 'Copied to clipboard')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: _ctx.copyOutline
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customOptionalParametersValue, (parameter, index) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: index,
                        lines: _ctx.currentJob.paused === 'Y' ? 'none': ''
                      }, {
                        default: _withCtx(() => [
                          (_ctx.currentJob.paused === 'Y')
                            ? (_openBlock(), _createBlock(_component_ion_input, {
                                key: 0,
                                label: parameter.name,
                                placeholder: parameter.name,
                                modelValue: parameter.value,
                                "onUpdate:modelValue": ($event: any) => ((parameter.value) = $event),
                                "helper-text": parameter.type
                              }, null, 8, ["label", "placeholder", "modelValue", "onUpdate:modelValue", "helper-text"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(parameter.name), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(parameter.value), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ], 64))
                        ]),
                        _: 2
                      }, 1032, ["lines"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_ion_item, {
              key: 1,
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "ion-text-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('This job does not have any custom parameters.')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _: 1
    })
  ], 64))
}