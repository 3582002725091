import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            (_ctx.isMaargJob)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentJob?.enumDescription ? _ctx.currentJob.enumDescription : _ctx.currentJob.jobName), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentJob?.enumName ? _ctx.currentJob.enumName : _ctx.currentJob?.description), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.jobHistory?.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.translate("No jobs have run yet")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.isMaargJob)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobHistory, (history, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, _toDisplayString(_ctx.getTime(history.startTime)), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.getDate(history.startTime)), 1)
                              ]),
                              _: 2
                            }, 1024),
                            (history.endTime)
                              ? (_openBlock(), _createBlock(_component_ion_badge, {
                                  key: 0,
                                  color: "dark"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.timeTillRun(history.endTime)), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobHistory, (job, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(job.runTime ? _ctx.getTime(job.runTime) : "-") + " ", 1),
                                (job.runTime)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.getDate(job.runTime)), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            (job.statusId)
                              ? (_openBlock(), _createBlock(_component_ion_badge, {
                                  key: 0,
                                  color: job.statusId === 'SERVICE_FINISHED' ? 'success' : 'danger'
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.getStatusDesc(job.statusId)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
            ]))
      ]),
      _: 1
    })
  ], 64))
}